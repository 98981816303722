body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

{
box-sizing: border-box;
}

.site-title {
  font-size: 1rem;
  margin:10px;
}

.nav-transparent {
  background-color: rgba(100, 120, 168, 0.2);
  z-index: 10000;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;
  padding: 0;
}

.nav-transparent ul {
  padding: 0;
  margin: 0;
  margin-right:3rem;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-transparent a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav-transparent li.active {
  font-weight:bold;
}

.nav-transparent li:hover {
  font-weight:bold;
}
.nav-solid {
  background-color: rgba(255, 255, 255, 1);
  z-index: 10000;
  position: fixed;
  top: 0;
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}
.nav-solid ul {
  padding: 0;
  margin: 0;
  margin-right:3rem;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-solid a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav-solid li.active {
  font-weight:bold;
}

.nav-solid li:hover {
  font-weight:bold;
}

.menu-icon {
  scale:1;
  padding:1rem;
}

.nav-menu {
  background-color: rgba(255, 255, 255, 1);
  z-index: 10000;
  position:fixed;
  top:0;
  margin-top:0;
  width: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 0 1rem;
}

.nav-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  gap: 0;
  width:100%;
}

.nav-menu a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: block;
  align-items: center;
  text-align:center;
  padding:2%;
  font-size:50px;
}

.text-row-title {
  color:#585951;
  background-color: #FAF9F6;
  font-size: 25px;
  font-family: 'Bohemian Cassidy Free', sans-serif;
  height:auto;
  margin:2%;
  padding:0;
}

.text-row-question {
  color:#5593c8;
  background-color: #FAF9F6;
  font-size: 20px;
  font-weight:bold;
  font-family: 'Bellerose', sans-serif;
  height:auto;
  margin:0;
  padding:0;
  width:100%;
}

.text-row-container {
  height:100%;
  background-color: #FAF9F6;;
  font-family: "Bellerose", sans-serif;
  font-size: 20px;
  color:#585951;
  width:border-box;
  padding-bottom:10px;
}

.box {

}

.text-row {
  height: 100%;
}

.photo-row {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;
  display:inline-block;
  background-color: inherit;
}

.text-row-left {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;

  display:inline-block;
  width:40%;
  background-color: inherit;
}

.text-row-right {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;
  border-left:2px solid;
  border-color: #585951;
  display:inline-block;
  width:40%;
  background-color: inherit;
}

.text-row-left-mobile {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;


  width:border-box;
  background-color: inherit;
}

.text-row-right-mobile {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;

  width:border-box;
  background-color: inherit;
}

.text-row-left-mini {
  display:inline-block;
  width:10vh;
  text-align:right;
  background-color: inherit;
}

.text-row-right-mini {
  display:inline-block;
  text-align:right;
  width:25vh;
  background-color: inherit;

}


.text-row-center {
  padding:20px;
  padding-top:40px;
  padding-bottom:40px;
  display:inline-block;
  width:border-box;
  background-color: inherit;
}

.text-row-faqs {
  padding-left:20px;
  padding-right:20px;
  padding-top:0;
  padding-bottom:30px;
  display:inline-block;
  max-width:70vh;
  width:border-box;
  background-color: inherit;
}

p span {
  display: block;
}

.text-row-container-photos {
  height:100%;
  display:flex;
  background-color: #FAF9F6;;
  font-family: "Bellerose", sans-serif;
  font-size: 20px;
  color:#585951;
  width:border-box;
  padding-bottom:10px;
}

